import * as React from "react"
import Button from "../Button/Button"
import { ContactStyles } from "./ContactStyles"
import { TfiWrite } from "react-icons/tfi"


const Contact = () => {
  return (
    <ContactStyles className="section">
      {/*  <form name="contact" netlify>
        <input placeholder="Your name..." type="text" name="name" />
        <input placeholder="Your email..." type="email" name="email" />
        <textarea
          placeholder="Your message..."
          name="message"
          rows="5"
        ></textarea>
        <Button text="Send Message" />
      </form> */}
      <div className="headerContact">
        <span className="icon" >
          <TfiWrite />
        </span>
        <h4 >
          Utilizza la form per scriverci o richiedere un preventivo
      </h4>
      </div>
      <form name="Contact Form" method="POST" data-netlify="true" data-netlify="true" action="/grazie">
        <input type="hidden" name="form-name" value="Contact Form" />
        <div >
          <label>Nome</label>
          <input type="nome" required name="nome" placeholder="Nome e Cognome" />
        </div>
        <div >
          <label>Email</label>
          <input type="email" required name="email" placeholder="email" />
        </div>
        <div >
          <label>Messaggio:</label>
          <textarea name="message" required placeholder="messaggio" />
        </div>
        <div className="d-flex">
          <input type="checkbox" required id="gdprCHeck" name="gdprCHeck" />
          <label htmlFor="gdprCHeck">  Ho letto l'<a target="_blank" href="https://www.iubenda.com/privacy-policy/14903152">informativa sulla privacy</a> e acconsento alla memorizzazione dei miei dati, secondo quanto stabilito dal regolamento europeo per la protezione dei dati personali n. 679/2016 (GDPR), per avere informazioni sui servizi di www.miosito.it</label>
        </div>
        <div className="holder-invia">
          <button className="invia inverse" type="submit">Invia</button>
        </div>
      </form>
    </ContactStyles>
  )
}

export default Contact
