import styled from "styled-components"

export const ContactStyles = styled.section`
  max-width: 750px;
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  color: var(--primary);

  .headerContact {
    padding-bottom:10px;
    border-bottom:1px solid var(--primary);
    margin-bottom:30px;
    display:flex;
    align-items:center;
   
    .icon {
      font-size:4rem;
      margin-right:10px;
    }

    h4 {
      margin-bottom:0;
      font-weight:700;
    }
  }

  input {
    height: calc(var(--gap) * 2);
    margin-bottom: var(--gap);

   
  }

  .d-flex {
    display:flex;
  }

  input[type='checkbox']{
    max-width:15px;
    margin-right:10px;
  }

  .invia {
      color: var(--primary);
      background-color: #fff;
      font-family: "Crete Round", sans-serif;
      border: 0;
      text-decoration: none;
      padding: 2px 4px;
      transition: color 0.3s ease;
      text-transform: capitalize;
      font-size: var(--p);
      font-weight: 700;
      position: relative;
      
      align-self: flex-start;
      display: inline-flex;
      align-items: center;
      @media(min-width:768px) {
        font-size: 22px;
      }

      &.inverse{
        color:#fff;
        background-color:  var(--primary);
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        height: 3px;
        left: 0;
        right: 0;
        bottom: 8px;
        background-color: var(--primary);
        transition: left 0.3s ease;
        top: 27px;
      }

      &:focus {
        color: var(--primary);
      }

      @media (hover: hover) {
        &:hover {
          cursor: pointer;
          /* color: var(--primary); */
          color: var(--primary) !important;
          background: var(--background);

          &::after {
            left: 100%;
          }
        }
      }
  }

  .holder-invia {
    width: 100%;
    text-align: center;
  }
  

  input,
  textarea {
    background-color: var(--primary);
    color: #fff;
    border: none;
    outline: 2px solid rgba(255, 255, 255, 0.15);
    border-radius: 6px;
    width: 100%;
    font-size: var(--p);
    font-weight: 700;
    font-family: "Heebo", sans-serif;
    padding: 15px;
    transition: outline 0.3s ease;

    &:focus {
      outline: 2px solid var(--primary);
    }

    &::-webkit-input-placeholder {
      color: var(--inActiveWhite);
    }

    &::-moz-placeholder {
      color: var(--inActiveWhite);
    }

    &:-ms-input-placeholder {
      color: var(--inActiveWhite);
    }

    &:-moz-placeholder {
      color: var(--inActiveWhite);
    }
  }
  textarea {
    margin-bottom: var(--gap);
    height:240px;
  }
`
